import React, { Fragment, useEffect, useState } from 'react';
import BigNumber from 'bignumber.js';
import classNames from 'classnames';
import { motion } from 'framer-motion';
import { chainflipAssetMonochromaticChainLogo } from '@/shared/assets/chains/logo';
import { type ChainflipToken } from '@/shared/assets/tokens';
import { ChainTokenLogo, CompactSwapRoute, CopyButton, Link, Tooltip } from '@/shared/components';
import QuestionMarkTooltip from '@/shared/components/QuestionMarkTooltip';
import { Rate } from '@/shared/components/Rate';
import RouteTooltip from '@/shared/components/RouteTooltip';
import { isTestnet } from '@/shared/featureFlags';
import { usePriceOracle } from '@/shared/hooks/usePriceOracle';
import ArrowRightIcon from '@/shared/icons/ArrowRight';
import Bolt from '@/shared/icons/flip-ui-kit/small/Bolt';
import Chevron from '@/shared/icons/flip-ui-kit/small/Chevron';
import { FireIcon } from '@/shared/icons/small';
import {
  abbreviate,
  intervalToDurationWords,
  isNullish,
  formatUsdValue,
  type TokenAmount,
} from '@/shared/utils';
import { UsdAmount } from './UsdAmount';
import usePriceDelta from '../hooks/usePriceDelta';
import useSettingsStore, { selectDepositMode } from '../hooks/useSettingsStore';
import { type RouteResponse, type StatusResponse } from '../integrations';
import { BOOST_FEE, EGRESS_FEE, INGRESS_FEE, NETWORK_FEE } from '../utils/consts';

const RouteLabelValue = ({
  label,
  children,
  tooltip,
}: {
  label: string;
  children: React.ReactNode | string;
  tooltip?: string;
}) => (
  <div className="flex w-full items-center justify-between text-12">
    <div className="flex items-center space-x-2">
      <span className="text-cf-light-2">{label}</span>
      <QuestionMarkTooltip content={tooltip} />
    </div>
    {typeof children === 'string' ? (
      <span className="font-aeonikMono text-white">{children}</span>
    ) : (
      children
    )}
  </div>
);

const RouteSection = ({ route, disabled }: { route: RouteResponse; disabled: boolean }) => {
  const [showRouteDetails, setShowRouteDetails] = useState<boolean>(false);

  if (route.integration === 'chainflip') {
    const swap = {
      sourceAsset: route.srcToken.chainflipId,
      depositAmount: route.srcAmount.toString(),
      destinationAsset: route.destToken.chainflipId,
      egress: {
        amount: route.destAmount.toString(),
      },
      swaps: {
        nodes: [
          {
            intermediateAmount:
              route.steps.length === 2 ? route.steps[1].srcAmount.toString() : undefined,
          },
        ],
      },
    };
    return (
      <RouteLabelValue label="Route">
        <Tooltip content={<RouteTooltip type="Route" swapRequest={swap} />}>
          <CompactSwapRoute
            flat
            routeInfo={{
              sourceAsset: swap.sourceAsset,
              destinationAsset: swap.destinationAsset,
            }}
            swapRequests={[]}
          />
        </Tooltip>
      </RouteLabelValue>
    );
  }

  return (
    <>
      <RouteLabelValue label="Route">
        <div className="flex items-center justify-around">
          <div className="flex items-center justify-around space-x-1">
            {route.steps.map((step, index) => (
              <Fragment key={step.protocolName + step.srcToken.symbol}>
                <ChainTokenLogo token={step.srcToken} size="small" displayChainLogo={index !== 1} />
                <ArrowRightIcon className="text-cf-light-1" width="16px" height="16px" />
                {index === route.steps.length - 1 && (
                  <ChainTokenLogo token={step.destToken} size="small" />
                )}
              </Fragment>
            ))}
          </div>

          <button
            type="button"
            disabled={disabled}
            onClick={(e) => {
              e.stopPropagation();
              setShowRouteDetails(!showRouteDetails);
            }}
          >
            <Chevron
              flip={!showRouteDetails}
              className="cursor-pointer text-cf-light-3 duration-100"
            />
          </button>
        </div>
      </RouteLabelValue>

      <motion.div
        className="!mt-0 h-0 overflow-hidden font-aeonikMedium text-12 text-cf-light-2"
        animate={showRouteDetails ? 'open' : 'closed'}
        variants={{
          open: { height: 'auto', opacity: 1 },
          closed: { height: '0px', opacity: 0 },
        }}
        transition={{ duration: 0.15 }}
      >
        <div className="mt-2 flex flex-col space-y-2">
          {route.steps.map((step) => (
            <div key={step.protocolName + step.srcToken.symbol}>
              <div className="ml-1 flex items-end space-x-1">
                <div className="text-cf-light-2">
                  Swap{' '}
                  <span className="font-aeonikMono font-semibold">
                    {step.srcAmount.toPreciseFixedDisplay()}
                  </span>{' '}
                  {step.srcToken.symbol} to{' '}
                  <span className="font-aeonikMono font-semibold">
                    {step.destAmount.toPreciseFixedDisplay()}
                  </span>{' '}
                  {step.destToken.symbol} via {step.protocolName}
                </div>
              </div>
            </div>
          ))}
        </div>
      </motion.div>
    </>
  );
};

const PlatformFees = ({
  isSettledCard,
  disabled,
  route,
}: {
  route: RouteResponse;
  isSettledCard: boolean;
  disabled: boolean;
}) => {
  const [expandPlatformFees, setExpandPlatformFees] = useState(false);
  const depositMode = useSettingsStore(selectDepositMode);

  const priceOracle = usePriceOracle();
  useEffect(() => priceOracle.watchTokens(route.platformFees.map(({ token }) => token)), [route]);

  const platformFees =
    route.integration === 'chainflip' && depositMode === 'contract'
      ? route.platformFees.filter((fee) => fee.name !== INGRESS_FEE)
      : route.platformFees;

  const totalPlatformFeeUsd = BigNumber.sum(
    ...platformFees.map((fee) =>
      fee.amount.mul(priceOracle.getPrice(fee.token) ?? 0).toBigNumber(),
    ),
    0,
  );
  const hasBoostFee = platformFees.some((fee) => fee.name === BOOST_FEE);

  return (
    <>
      <button
        disabled={disabled}
        type="button"
        onClick={(e) => {
          e.stopPropagation();
          setExpandPlatformFees(!expandPlatformFees);
        }}
        className="w-full"
      >
        <RouteLabelValue label={isSettledCard ? 'Total fees' : 'Est. Total fees'}>
          <div className="flex items-center">
            {hasBoostFee && <Bolt className="mr-0.5 text-cf-pink-1" />}
            <span className="font-aeonikMono">{formatUsdValue(totalPlatformFeeUsd)}</span>
            <Chevron width={16} height={16} flip={!expandPlatformFees} />
          </div>
        </RouteLabelValue>
      </button>

      <motion.div
        className="overflow-hidden"
        initial="closed"
        animate={expandPlatformFees ? 'open' : 'closed'}
        variants={{
          open: { height: 'auto', opacity: 1, marginTop: 'auto' },
          closed: { height: '0px', opacity: 0, marginTop: '0px' },
        }}
        transition={{ duration: 0.15 }}
      >
        <div className="flex w-full flex-col space-y-2 pt-3">
          {platformFees.map((fee, index) => (
            <div
              className="flex w-full items-center justify-between text-cf-light-2"
              key={index} // eslint-disable-line react/no-array-index-key
            >
              <div className="flex flex-row items-center gap-x-2">
                {fee.name === INGRESS_FEE && <ChainTokenLogo token={fee.token} size="xsmall" />}
                {fee.name === EGRESS_FEE && <ChainTokenLogo token={fee.token} size="xsmall" />}
                {fee.name === NETWORK_FEE && <FireIcon className="text-cf-red-2" />}
                {fee.name === BOOST_FEE && <Bolt className="text-cf-pink-1" />}
                {fee.name}
                {fee.name === NETWORK_FEE && (
                  <QuestionMarkTooltip
                    content={
                      <div>
                        Fixed fee taken in $USDC by the protocol to buy and burn $FLIP.{' '}
                        <Link
                          href="https://docs.chainflip.io/concepts/token-economics/incentive-design-emission-and-burning#the-network-fee"
                          target="_blank"
                          color="green"
                        >
                          Learn more
                        </Link>
                      </div>
                    }
                  />
                )}
              </div>
              <div className="font-aeonikMono">
                <span className="text-white">
                  {fee.amount.toFixedDisplay()} {fee.token.symbol}{' '}
                </span>
                (<UsdAmount token={fee.token} tokenAmount={fee.amount} />)
              </div>
            </div>
          ))}
        </div>
      </motion.div>
    </>
  );
};

export const SwapDetails = ({
  route,
  duration,
  estimatedDefaultDuration,
  slippageTolerancePercent,
  destAddress,
  refundAddress,
  minReceived,
  isSettledCard = false,
  isRefundCard = false,
  disabled = false,
}: {
  route: RouteResponse;
  duration?: StatusResponse['duration'];
  estimatedDefaultDuration?: number;
  slippageTolerancePercent?: number;
  destAddress?: string;
  refundAddress?: string;
  minReceived?: TokenAmount;
  isSettledCard?: boolean;
  isRefundCard?: boolean;
  disabled?: boolean;
}) => {
  const { delta, color } = usePriceDelta({
    srcAmount: route.srcAmount,
    destAmount: route.destAmount,
    srcToken: route.srcToken,
    destToken: route.destToken,
  });

  const MonochromaticSrcChainLogo =
    route.integration === 'chainflip'
      ? chainflipAssetMonochromaticChainLogo[route.srcToken.chainflipId]
      : undefined;
  const MonochromaticDestChainLogo =
    route.integration === 'chainflip'
      ? chainflipAssetMonochromaticChainLogo[route.destToken.chainflipId]
      : undefined;

  return (
    <div className="w-full space-y-3 text-12">
      {!isRefundCard && <RouteSection route={route} disabled={disabled} />}
      <PlatformFees route={route} disabled={disabled} isSettledCard={isSettledCard} />
      {route.gasFees.map((fee, index) => (
        <RouteLabelValue
          label={isSettledCard ? 'Gas fees' : 'Est. Gas fees'}
          key={index} // eslint-disable-line react/no-array-index-key
        >
          <div className="font-aeonikRegular text-cf-light-2">
            <span className="text-white">
              <span className="font-aeonikMono">{fee.amount.toFixedDisplay()}</span>{' '}
              {fee.token.symbol}{' '}
            </span>
            <span className="font-aeonikMono">
              (<UsdAmount token={fee.token} tokenAmount={fee.amount} />)
            </span>
          </div>
        </RouteLabelValue>
      ))}
      {!isRefundCard && (
        <RouteLabelValue label={isSettledCard ? 'Rate' : 'Est. Rate'}>
          <Rate
            depositAmount={route.srcAmount.toString()}
            sourceAsset={(route.srcToken as ChainflipToken).chainflipId}
            egressAmount={route.destAmount.toString()}
            destinationAsset={(route.destToken as ChainflipToken).chainflipId}
            className="font-aeonikMono"
          />
        </RouteLabelValue>
      )}
      {slippageTolerancePercent && (
        <RouteLabelValue
          label="Slippage Tolerance"
          tooltip="Maximum accepted difference between quote price and swap price"
        >
          <span className="font-aeonikMono">{slippageTolerancePercent}%</span>
        </RouteLabelValue>
      )}
      {minReceived && !isRefundCard && !isSettledCard && (
        <RouteLabelValue
          label="Min. Received"
          tooltip={
            !isSettledCard
              ? 'Minimum amount received at the destination address based on slippage tolerance and estimated fees'
              : undefined
          }
        >
          <span className="font-aeonikMono">
            {minReceived.toFixedDisplay()} {route.destToken.symbol}
          </span>
        </RouteLabelValue>
      )}
      {isSettledCard && !isRefundCard && (
        <RouteLabelValue
          label="Price Delta"
          tooltip={`The difference between the global index price and the swap output ${
            isTestnet ? ' (not available for testnet assets)' : ''
          }`}
        >
          <span className={classNames('font-aeonikMono', color)}>
            {BigNumber.isBigNumber(delta) ? `${delta.toFixed(1)}%` : '-'}
          </span>
        </RouteLabelValue>
      )}
      {refundAddress && (
        <RouteLabelValue label="Refund Address">
          <Tooltip content={refundAddress}>
            <div className="flex items-center justify-around gap-x-1.5 font-aeonikMono">
              {MonochromaticSrcChainLogo && <MonochromaticSrcChainLogo />}
              <span className="text-cf-white">{abbreviate(refundAddress)}</span>
              <CopyButton textToCopy={refundAddress} />
            </div>
          </Tooltip>
        </RouteLabelValue>
      )}
      {destAddress && !isRefundCard && (
        <RouteLabelValue label="Destination Address">
          <Tooltip content={destAddress}>
            <div className="flex items-center justify-around gap-x-1.5 font-aeonikMono">
              {MonochromaticDestChainLogo && <MonochromaticDestChainLogo />}
              <span className="text-cf-white">{abbreviate(destAddress)}</span>
              <CopyButton textToCopy={destAddress} />
            </div>
          </Tooltip>
        </RouteLabelValue>
      )}
      {!isNullish(duration) && !isRefundCard && (
        <RouteLabelValue label="Total time">
          <div className="flex flex-row gap-x-2">
            <span className="text-white">
              {intervalToDurationWords({ start: 0, end: duration ?? 0 })}
            </span>
            {estimatedDefaultDuration && (
              <span className="text-cf-light-2 line-through">
                {intervalToDurationWords({ start: 0, end: estimatedDefaultDuration * 1000 })}
              </span>
            )}
          </div>
        </RouteLabelValue>
      )}
    </div>
  );
};
