import { type Token, isChainflipTokenOrChain } from '@/shared/assets/tokens';
import useSettingsStore from './useSettingsStore';
import useSwapRequestStore from './useSwapRequestStore';

const isBoostableDeposit = (token: Token | undefined) =>
  isChainflipTokenOrChain(token) && token.chainflipId === 'Btc';

export default function useBoost() {
  const srcToken = useSwapRequestStore((state) => state.srcToken);
  const boostEnabled = useSettingsStore((state) => state.boostEnabled);
  const depositBoostable = isBoostableDeposit(srcToken);
  return {
    boostAvailable: depositBoostable,
    boostActive: boostEnabled && depositBoostable,
  };
}
